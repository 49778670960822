<template>
  <v-card class="transparent">
    <v-expansion-panels mandatory>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('basicInfo') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-if="value && value.uuid"
            class="caption pb-2"
            dense
          >
            <v-col
              cols="12"
              class="pb-0"
            >
              {{ $t('couponUUID') }}
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              {{ value.uuid }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('couponCode') + ' *'"
                v-model="code"
                :disabled="!isNewCoupon || processing"
                persistent-hint
                :hint="$t('couponCodeHint')"
                :error="!value.code && parentProcessingError"
              >
                <template v-slot:append-outer>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        fab
                        small
                        style="margin-top: -12px;"
                        :loading="processing"
                        :disabled="disabled"
                        v-on="{...tooltip}"
                        @click="generateCode"
                      >
                        <template v-slot:loader>
                          <span class="custom-loader">
                            <v-icon light>mdi-cached</v-icon>
                          </span>
                        </template>
                        <v-icon>
                          mdi-cached
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('generateCode') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('name') + ' *'"
                v-model="value.label"
                :disabled="processing"
                persistent-hint
                :hint="$t('couponNameHint')"
                :error="!value.label && parentProcessingError"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('description')"
                v-model="value.description"
                :disabled="processing"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('limitations') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              cols="12"
              class="px-0 pb-0"
            >
              <div
                class="font-weight-medium grey lighten-2 pa-2"
                style="border-radius: 4px;"
              >
                {{ $t('item') }}
              </div>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :label="$t('couponItem')"
                :items="couponItems"
                v-model="couponItem"
                item-text="title"
                item-value="uuid"
                clearable
                persistent-hint
                :hint="$t('connectsTheCouponToAnItemForFurtherLimitations')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="px-0 pb-0"
            >
              <div
                class="font-weight-medium grey lighten-2 pa-2"
                style="border-radius: 4px;"
              >
                {{ $t('locations') }}
              </div>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :label="$t('locations')"
                :items="locations"
                v-model="couponLocations"
                item-text="label"
                item-value="uuid"
                multiple
                clearable
                persistent-hint
                :hint="$t('couponLocationLimitHint')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="px-0 pb-0"
            >
              <div
                class="font-weight-medium grey lighten-2 pa-2"
                style="border-top-left-radius: 4px; border-top-right-radius: 4px;"
              >
                {{ $t('customer') }}
              </div>
              <div style="border-bottom-left-radius: 4px; border-bottom-right-radius: 4px;">
                <v-alert
                  type="info"
                  text
                  tile
                  dense
                  class="caption mb-0"
                  style="border: inherit;"
                >
                  {{ $t('ifPersonDoesNotExistWillBeAddedInCouponProcess') }}
                </v-alert>
              </div>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('name')"
                v-model="customerName"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :label="$t('phone')"
                v-model="customerPhone"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :label="$t('email')"
                v-model="customerEmail"
                hide-details
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('redemptions') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              cols="12"
              v-if="!isNewCoupon"
            >
              <v-text-field
                :label="$t('timesRedeemed')"
                v-model="value.timesRedeemed"
                :disabled="processing"
                type="number"
                persistent-hint
                :hint="$t('couponTimesRedeemedHint')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('maxRedemptions')"
                v-model="value.maxRedemptions"
                :disabled="processing"
                type="number"
                persistent-hint
                :hint="$t('couponRedemptionsHint')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('percentOff%') + ' *'"
                v-model="value.percentOff"
                :disabled="processing"
                type="number"
                persistent-hint
                :hint="$t('couponPercentHint')"
                :error="!value.percentOff && parentProcessingError"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('timeSettings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-alert type="info">
                {{ $t('couponActiveHint') }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <DateAndTimeSelection
                :label="$t('activeFrom')"
                v-model="value.effectiveStartingAtTimestampInMillis"
                icon="mdi-calendar-today"
                type="from"
                @setDateTime="selectDateFrom"
                :original-value="originalFromTimeString"
              />
            </v-col>
            <v-col cols="12">
              <DateAndTimeSelection
                :label="$t('activeTo')"
                v-model="value.effectiveEndingAtTimestampInMillis"
                icon="mdi-calendar"
                type="to"
                @setDateTime="selectDateTo"
                :original-value="originalToTimeString"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                prepend-icon="mdi-calendar-range"
                :label="$t('activeWeekdays')"
                :hint="$t('activeWeekdaysHint')"
                multiple
                :placeholder="$t('activeWeekdaysPlaceholder')"
                persistent-hint
                chips
                deletable-chips
                clearable
                :items="weekdays"
                v-model="selectedWeekdays"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              {{ $t('activeBetweenSpecificHours') }}
            </v-col>
            <v-col
              cols="12"
            >
              <v-alert
                type="info"
                class="mb-0"
              >
                {{ $t('couponHoursHint') }}
              </v-alert>
            </v-col>
            <v-col
              cols="12"
              v-if="!Array.isArray(value.validHours) || value.validHours.length < 1"
              class="text-center text--secondary"
            >
              {{ $t('noActiveHoursSet') }}
            </v-col>
            <template v-for="(hour, hourIndex) in value.validHours">
              <v-col
                cols="12"
                md="5"
                class="pt-0"
                :key="`start-${hourIndex}`"
              >
                <TimeSelection
                  :label="$t('activeFrom')"
                  hide-details
                  :value="hour.startTime"
                  @input="(e) => {
                    if(e && !e.includes(':')) {
                      e = `${e.substr(0,2)}:${e.substr(2,4)}`
                    }
                    hour.startTime = e
                  }"
                />
              </v-col>
              <v-col
                cols="12"
                md="5"
                class="pt-0"
                :key="`end-${hourIndex}`"
              >
                <TimeSelection
                  :label="$t('activeTo')"
                  hide-details
                  :value="hour.endTime"
                  @input="(e) => {
                    if(e && !e.includes(':')) {
                      e = `${e.substr(0,2)}:${e.substr(2,4)}`
                    }
                    hour.endTime = e
                  }"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                :key="`remove-${hourIndex}`"
              >
                <v-btn
                  color="error"
                  block
                  text
                  @click="() => { value.validHours.splice(hourIndex, 1) }"
                >
                  {{ $t('remove') }}
                </v-btn>
              </v-col>
            </template>
            <v-col
              cols="12"
            >
              <v-btn
                text
                block
                color="primary"
                @click="addNewValidHour"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                {{ $t('addNewActiveHours') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row
      v-if="!isNewCoupon"
      class="pt-3"
      justify="space-between"
    >
      <v-col
        cols="12"
        md="auto"
      >
        <v-btn
          :disabled="processing"
          :loading="processing"
          x-large
          color="error"
          block
          @click="disableCoupon"
          v-if="value.active === true"
        >
          {{ $t('disable') }}
        </v-btn>
        <v-btn
          :disabled="processing"
          :loading="processing"
          x-large
          color="success"
          block
          @click="enableCoupon"
          v-if="value.active === false"
        >
          {{ $t('enable') }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="auto"
      >
        <v-btn
          :disabled="processing"
          :loading="processing"
          x-large
          color="primary"
          block
          @click="update"
        >
          {{ $t('update') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DateAndTimeSelection from "@/components/common/DateAndTimeSelection"
import TimeSelection from "@/components/common/TimeSelection"

export default {
  name: 'CouponForm',
  components: {
    TimeSelection,
    DateAndTimeSelection
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    parentIsProcessing: {
      type: Boolean
    },
    parentProcessingError: {
      type: Boolean
    }
  },
  data: () => ({
    processing: false,
    isNewCoupon: false,
    originalFromTimeString: null,
    originalToTimeString: null,
    item: null,
    result: null,
  }),
  watch: {
    parentIsProcessing(value) {
      this.processing = value
    },
    value(newValue, oldValue) {
      if (newValue.uuid !== oldValue.uuid) {
        this.item = this.value.item.uuid
      }

    }
  },
  methods: {
    generateCode() {
      this.processing = true
      this.$emit('childIsProcessing', true)
      this.$store.dispatch('generateCode').then(result => {
        this.code = result.generateCode
        this.processing = false
        this.$emit('childIsProcessing', false)
      })
    },
    clickedOutsideDateFrom() {
      if(this.originalFromTimeString !== this.selectedDateFrom) {
        this.selectedDateFrom = this.originalFromTimeString
      }
    },
    selectDateFrom(timeString) {
      //this.selectedDateFrom = timeString
      this.value.effectiveStartingAtTimestampInMillis = timeString
    },
    selectDateTo(timeString) {
      //this.selectedDateTo = timeString
      this.value.effectiveEndingAtTimestampInMillis = timeString

    },
    update() {
      this.processing = true
      this.value.couponItem = this.couponItem
      this.$store.dispatch('updateCoupon', this.value).then(result => {
        if(result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('couponSuccessfullyUpdatedTheCoupon')
          })
        }
        else {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: 'Could not update the coupon.'
          })
          this.showError = true
        }
        this.$emit('success', !!result)
        this.result = result
      }).catch(() => {
        this.$store.commit('updateActionError', {
          message: 'Error',
          subMessage: 'Could not update the coupon.'
        })
        this.showError = true
      }).finally(() => {
        this.processing = false
      })
    },
    disableCoupon() {
      this.processing = true
      this.value.active = false
      this.$store.dispatch('updateCoupon', this.value).then(result => {
        if(result) {
            this.$store.commit('updateActionSuccess', {
              message: 'Success',
              subMessage: 'You have successfully disabled the coupon.'
            })
          }
          else {
            this.$store.commit('updateActionError', {
              message: 'Error',
              subMessage: 'Could not disable the coupon.'
            })
            this.showError = true
          }
        })
        .catch(() => {
          this.$store.commit('updateActionError', {
            message: 'Error',
            subMessage: 'Could not disable the coupon.'
          })
          this.showError = true
        }).finally(() => {
        this.processing = false
      })
    },
    enableCoupon() {
      this.processing = true
      this.value.active = true
      this.$store.dispatch('updateCoupon', this.value).then(result => {
        if(result) {
            this.$store.commit('updateActionSuccess', {
              message: 'Success',
              subMessage: 'You have successfully enabled the coupon.'
            })
          }
          else {
            this.$store.commit('updateActionError', {
              message: 'Error',
              subMessage: 'Could not enable the coupon.'
            })
            this.showError = true
          }
        })
        .catch(() => {
          this.$store.commit('updateActionError', {
            message: 'Error',
            subMessage: 'Could not enable the coupon.'
          })
          this.showError = true
        }).finally(() => {
        this.processing = false
      })
    },
    addNewValidHour() {
      if(typeof this.value !== 'undefined' && this.value !== null) {
        if(!Array.isArray(this.value.validHours)) {
          this.value.validHours = []
        }
        this.value.validHours.push({
          startTime: '00:00',
          endTime: '23:59'
        })
      }
    }
  },
  mounted() {
    if(!this.value || !this.value.uuid) {
      this.isNewCoupon = true
    }
    if(this.value && this.value.item) {
      this.item = this.value.item.uuid
    }
    this.originalFromTimeString = this.selectedDateFrom
    this.originalToTimeString = this.selectedDateTo
  },
  computed: {
    userIsAdmin() {
      return this.$store.getters.userIsAdmin
    },
    couponItem: {
      get() {
        return this.item
      },
      set(value) {
        this.item = value
        this.$emit('setCouponItem', value)
      }
    },
    couponLocations: {
      get() {
        return this.value.validLocations
      },
      set(value) {
        this.value.validLocations = value
        this.$emit('setValidLocations', value)
      }
    },
    customerName: {
      get() {
        if(this.value && this.value.customer && this.value.customer.address && this.value.customer.address.name_line) {
          return this.value.customer.address.name_line
        } else {
          return null
        }
      },
      set(value) {
        if(!this.value.customer) {
          this.value.customer = {}
        }
        if(!this.value.customer.address) {
          this.value.customer.address = {}
        }
        this.value.customer.address.name_line = value
      }
    },
    customerPhone: {
      get() {
        if(this.value.customer && this.value.customer.phone) {
          return this.value.customer.phone
        }
        return null
      },
      set(value) {
        if(!this.value.customer) {
          this.value.customer = {}
        }
        this.value.customer.phone = value
      }
    },
    customerEmail: {
      get() {
        if(this.value.customer && this.value.customer.email) {
          return this.value.customer.email
        }
        return null
      },
      set(value) {
        if(!this.value.customer) {
          this.value.customer = {}
        }
        this.value.customer.email = value
      }
    },
    couponItems() {
      if(typeof this.$store.state.items === 'undefined' || this.$store.state.items === null) {
        return []
      }
      return this.$store.state.items.filter(item => item.type === "coupon" && item.status === true)
    },
    locations() {
      return this.$store.state.locations
    },
    selectedDateFrom: {
      get() {
        return this.value.effectiveStartingAtTimestampInMillis
      },
      set(value) {
        this.value.effectiveStartingAtTimestampInMillis = value
      }
    },
    selectedDateTo: {
      get() {
        return this.value.effectiveEndingAtTimestampInMillis
      },
      set(value) {
        this.value.effectiveEndingAtTimestampInMillis = value
      }
    },
    weekdays() {
      return this.$store.getters.weekdays
    },
    currentUserIsSalesCloudStaff() {
      return this.$store.getters.userIsAdmin
    },
    selectedWeekdays: {
      get() {
        const weekdays = this.value.validWeekdays
        if(weekdays === null || weekdays === undefined) {
          return []
        } else {
          return weekdays
        }
      },
      set(value) {
        this.value.validWeekdays = value.sort((a,b) => a - b)
      }
    },
    disabled() {
      return this.processing || !this.isNewCoupon
    },
    code: {
      get() {
          return this.value.code
      },
      set(value) {
        this.value.code = value.toUpperCase()
      }
    }
  }
}
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
